<template>
  <div class="aboutus">
    <div class="aboutus-left">
      <div
        v-for="item in pathlist"
        :class="type == item.type ? 'active-div' : null"
        @click="godetails(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="aboutus-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutus",
  data() {
    return {
      type: "1",
      pathlist: [
        { title: "关于我们", type: "1", path: "/aboutus/we" },
        { title: "联系我们", type: "2", path: "/aboutus/concat" },
        // { title: "加入我们", type: "3", path: "/aboutus/join" },
        { title: "合作伙伴", type: "4", path: "/aboutus/cooperation" },
      ],
    };
  },
  methods: {
    godetails(item) {
      if (this.$route.path != item.path) {
        this.type = item.type;
        this.$router.push(item.path);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.aboutus {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  .aboutus-left {
    width: 240px;
    height: 200px;
    margin-right: 20px;
    background: white;
    div {
      height: 25%;
      text-indent: 20px;
      line-height: 50px;
      border-bottom: 1px solid #eaeaea;
      cursor: pointer;
      &:hover {
        color: white;
        background: #0966a9;
      }
    }
    .active-div {
      background: #0966a9;
      color: white;
    }
  }
  .aboutus-right {
    width: 100%;
    padding: 20px;
    background: white;
  }
}
</style>